import React, { useState } from 'react'
import Pricing from '../containers/CustomContainers/Pricing'
import FaqCustom from '../containers/CustomContainers/Faq/faqcustom'
import BannerSectionCustom from '../containers/CustomContainers/Banner'
import FooterCustom from '../containers/CustomContainers/Footer'
import HeadermenuCustom from '../containers/CustomContainers/HeadermenuBs'
import ContactPoc from '../containers/CustomContainers/Contact'
import FeaturesVideoVersion from '../containers/CustomContainers/FeaturesVideoVersion'
import BlogPosts from '../containers/CustomContainers/BlogPosts'
import Products from '../containers/CustomContainers/Products'
import TextDescription from '../containers/CustomContainers/SimpleTextDescription'
import CookieContainer from '../containers/CustomContainers/CookieContainer'
import GlobalStyle from '../containers/CustomContainers/Common/global-styles'
import '../components/layout.css'
import Seo from '../components/seo'
import Fonts from '../containers/CustomContainers/Common/fonts'
import { graphql } from 'gatsby'
import BlockUi from 'react-block-ui'
import 'react-block-ui/style.css'
import Partners from '../containers/CustomContainers/Partners'

const Pro = ({ data }) => {
    const AppName = 'PowerBI Tiles Pro'
    const SharedData = data.sharedJson
    const proJson = data.proJson
    const [blocking, setBlocking] = useState(false)
    const [expanded, setExpanded] = useState(false)

    var FaqsObj = {
        jsonData: proJson.FAQ,
        markdownData: data.allMarkdownRemark.edges
    }

    /*const faqs = data.allPowerBiTilesPowerBiTilesFaqsListItem.nodes.map(item => {
        const content = JSON.parse(item.internal.content)
        if (content && content.fields && content.fields.TesteImagem) {
            content.fields.TesteImagem = JSON.parse(content.fields.TesteImagem)
        }
        return content
    })

    console.log(faqs)*/

    const partners = data.allPowerBiTilesPowerBiTilesPartnersListItem.nodes.map(item => {
        const content = JSON.parse(item.internal.content)
        if (content && content.fields && content.fields.Logo) {
            content.fields.Logo = JSON.parse(content.fields.Logo)
        }
        return content
    })

    return (
        <BlockUi
            tag="div"
            blocking={blocking}
            message="Submiting your message"
            keepInView
        >
            <React.Fragment>
                {
                    /*faqs.length > 0 && faqs.map((item) => {
                        if (item.fields && item.fields.TesteImagem) {
                            return <img
                                src={item.fields.TesteImagem.serverUrl + item.fields.TesteImagem.serverRelativeUrl}/>
                        }
                    })*/
                }
                <div>
                    <Fonts/>
                    <GlobalStyle/>
                    <HeadermenuCustom
                        data={proJson.Header}
                        expanded={expanded}
                        expandToggle={newValue => setExpanded(newValue)}
                    />
                    <main onClick={() => setExpanded(false)}>
                        <Seo
                            title={proJson.SEO.Title}
                            description={proJson.SEO.Description}
                            favIconPath={proJson.SEO.FavIconPath}
                            previewImage={proJson.SEO.PreviewImage}
                            keywords={proJson.SEO.Keywords}
                        />
                        <BannerSectionCustom data={proJson.Banner}/>
                        <TextDescription data={proJson.TextDescription}/>

                        <FeaturesVideoVersion data={proJson.FeaturesVideoVersion}/>

                        <Pricing data={proJson.Pricing}/>

                        <FaqCustom data={FaqsObj}/>
                        <Products
                            sharedData={SharedData.Products}
                            data={proJson.Products}
                        />

                        <BlogPosts data={proJson.BlogPosts}/>
                        <ContactPoc
                            sharedData={SharedData.ContactUs}
                            data={proJson.ContactUs}
                            blockUIChange={newValue => setBlocking(newValue)}
                            appName={AppName}
                        />

                        <Partners data={partners}/>
                    </main>
                    <FooterCustom data={proJson.Footer}/>
                </div>
                <CookieContainer sharedData={SharedData.Cookie}/>
            </React.Fragment>
        </BlockUi>
    )
}

/*

        allPowerBiTilesPowerBiTilesFaqsListItem(
            sort: {order: ASC, fields: [data___fields___Tab, data___fields___ColumnOrder]}
        ) {
            nodes {
                internal {
                    content
                }
            }
        }
 */
export const pageQuery = graphql`
    query ProductPageQuery {
        allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/(Pro/FAQS)/" } }
            sort: { order: ASC, fields: [frontmatter___category, frontmatter___path] }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        category
                    }
                    html
                }
            }
        }

        allPowerBiTilesPowerBiTilesPartnersListItem(
            sort: {order: ASC, fields: [data___fields___ColumnOrder]}
        ) {
            nodes {
                internal {
                    content
                }
            }
        }
        
        sharedJson {
            Products {
                Items {
                    ProductsCardIcon
                    ProductsCardHeading
                    ProductsCardPara
                    ProductRoute
                }
            }
            ContactUs {
                SectionHeading
                SectionSubHeading
                ContactRight {
                    ContactRightHeading
                    ContactRightPara
                }
            }
            Cookie {
                CookieName
                CookieExpireDays
                SaveCookieOnDecline
                OkButtonMessage
                CookieMessage
            }
        }
        proJson {
            SEO {
                Title
                Description
                FavIconPath
                PreviewImage
                Keywords
            }
            Header {
                BrandLogo
                BrandLogoSticky
                Items
                MenuItems {
                    Menu
                    Href
                    LocalLink
                }
            }
            Banner {
                BannerHeading
                BannerDescription
                WatchVideoAnchor
                BtnText {
                    Title
                    Items {
                        Name
                        Href
                    }
                    MultiSelection
                }
                BtnText2 {
                    Title
                    Items {
                        Name
                        Href
                    }
                    MultiSelection
                }
                PathToImage
            }
            TextDescription {
                BackgroundGrey
                Heading
                Description
                Border
            }
            FeaturesVideoVersion {
                BackgroundGrey
                FeaturesSubHeading
                FeaturesHeading
                Features {
                    FeaturesVideoPositionLeft
                    FeaturesVideo
                    FeaturesHeading
                    FeaturesDescription
                    Button {
                        Title
                        Items {
                            Name
                            Href
                        }
                        MultiSelection
                    }
                }
                DelayConstant
            }
            Pricing {
                BackgroundGrey
                DelayConstant
                PricingHeading
                PricingSubHeading
                PricingPlanCol {
                    PricingPlanHeading
                    PricingPlanImg
                    PricingPlanPrice
                    PricingPlanPriceSpan
                    PricingPlanDarkText {
                        Text
                    }
                    PricingPlanBtn
                    PricingPlanBtnHref
                }
            }
            FAQ {
                BackgroundGrey
                FaqSectionSubHeading
                FaqSectionHeading
                DelayConstant
                FaqTabsOrder
            }
            Products {
                BackgroundGrey
                DelayConstant
                ProductsLeftHeading
                ProductsLeftPara
                ProductsLeftBtn {
                    Title
                    Items {
                        Name
                        Href
                    }
                    MultiSelection
                }
            }
            BlogPosts {
                BackgroundGrey
                SectionHeading

                BlogImg1
                BlogName1
                BlogDesc1
                DetailsText1
                Blog1Button {
                    Name
                    Href
                }

                BlogImg2
                BlogName2
                BlogDesc2
                DetailsText2
                Blog2Button {
                    Name
                    Href
                }

                BlogImg3
                BlogName3
                BlogDesc3
                DetailsText3
                Blog3Button {
                    Name
                    Href
                }
            }
            Footer {
                FooterLogo
                Items
                FooterCol2 {
                    FooterTitle
                    FooterUl {
                        FooterMenu
                        Href
                        LocalLink
                    }
                }
                FooterCol3 {
                    FooterTitle
                    FooterUl {
                        FooterMenu
                        Href
                    }
                }
                FooterCol4 {
                    FooterTitle
                    FooterAddWrapper {
                        FooterAddImg
                        FooterAddText
                    }
                }
                BottomFooterPara
                BottomFooterName
                BottomFooterLink
                SocialLinks {
                    FacebookLink
                    TwitterLink
                    LinkedinLink
                    InstagramLink
                }
            }
            ContactUs {
                BackgroundGrey
            }
        }
    }
`

export default Pro
