import React from 'react';
import {TestimonialsSection,HeadingLayout,Heading,SliderOuterLayout,
    TestimonialsOuterWrapper,SliderInnerLayout,TestimonialLayout
} from './testimonials.style';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const TextDescription = ({data}) => {    
    const color = data.BackgroundGrey ? "#f9fafc" : "#FFFFFF";

    return (
        <TestimonialsSection id="simpleTextContainer" style={{background: color}}>
                <HeadingLayout>
                    <Heading>
                    {data.Heading}
                    </Heading>
                    <img src={data.Border}  alt=""/>
                </HeadingLayout>

                <SliderOuterLayout>
                    <Container>
                        <TestimonialsOuterWrapper>
                            <SliderInnerLayout>
                                <TestimonialLayout>
                                    <p>
                                    {data.Description}
                                    </p>
                                </TestimonialLayout>
                            </SliderInnerLayout>                               
                        </TestimonialsOuterWrapper>
                    </Container>
                </SliderOuterLayout>
            </TestimonialsSection>
    );
}

export default TextDescription;